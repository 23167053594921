import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import Block from '../../../components/block'
import * as BaseButton from '../../../components/button'
import Dialog from '../../../components/dialog'
import { Input } from '../../../components/input-control'
import { Horizontal } from '../../../components/layout'
import Label from '../../../components/label'
import ReactLoading from 'react-loading'
import theme from '../../../styles/theme'
import ActiveInventoryApi from '../../../apis/active-inventory'
import SuccessfullyConcludedDealApi from '../../../apis/successfully-concluded-deal'
import { isValid, parse } from 'date-fns'
import {
  ActiveInventoryConfig,
  SuccessfullyConcludedDealConfig,
} from '../../../schema/contract'
import { SubSectionTitle, Text } from 'components/typography'
const Body = styled.div`
  height: auto;
`
const Button = styled(BaseButton.default)`
  width: 100%;
  span {
    font-size: 16px;
  }

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    span {
      font-size: 14px;
    }
  }
`

const PhraseWrapper = styled.div`
  gap: 20px;
  padding-top: 10px;
  display: flex;
`
const FixedPhraseWrapper = styled(Horizontal)`
  gap: 20px;
`
const FullPhrase = styled(Button)`
  width: 100%;
  margin-bottom: 10px;
`

const Error = styled.label`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: red;
`

const Alert = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: red;
`

const InputArea = styled.div`
  margin-bottom: 20px;
`

const NumberInput = styled(Input)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`

const PropertyExampleSettingDialog = (props: {
  contractId: string
  config: ActiveInventoryConfig | SuccessfullyConcludedDealConfig | undefined
  onSuccess: () => void
}) => {
  const [showDialog, setShowDialog] = useState(false)

  const open = () => {
    setShowDialog(true)
  }
  const close = () => {
    setShowDialog(false)
  }

  return (
    <>
      <FixedPhraseWrapper>
        <FullPhrase as={Button} onClick={open} color="primary">
          <span>売出物件と成約事例の表示条件を設定</span>
        </FullPhrase>
      </FixedPhraseWrapper>
      <SettingDialog
        contractId={props.contractId}
        config={props.config}
        showDialog={showDialog}
        onCancel={close}
        onSuccess={props.onSuccess}
      />
    </>
  )
}

const ErrorText = styled.div`
  margin-top: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #ea1a1a;
`
const wheelDisabled = (e: any) => {
  e.target.addEventListener(
    'wheel',
    (e: any) => {
      e.preventDefault()
    },
    { passive: false }
  )
}

const ConfigInput = (props: {
  contractId: string
  config: ActiveInventoryConfig | undefined
  onReturn: () => void
  onClear: () => void
  close: () => void
  onSuccess: () => void
  onSearchNoData: (type: string) => void
}) => {
  const [address, setAddress] = useState<string | undefined>(
    props.config?.address
  )
  const [showAddressError, setShowAddressError] = useState(false)
  const [addressError, setAddressError] = useState(!props.config?.address)

  const [size, setSize] = useState<number | undefined>(props.config?.square)
  const [showSizeError, setShowSizeError] = useState(false)
  const [sizeError, setSizeError] = useState(!props.config?.square)

  const [buildYearMonth, setBuildYearMonth] = useState<string | undefined>(
    props.config?.buildYearMonth
  )
  const [showBuildYearMonthError, setShowBuildYearMonthError] = useState(false)
  const [buildYearMonthError, setBuildYearMonthError] = useState(
    !props.config?.buildYearMonth
  )

  const [isLoading, setIsLoading] = useState(false)

  const hasError = useMemo(() => {
    return sizeError || addressError || buildYearMonthError
  }, [sizeError, addressError, buildYearMonthError])

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setAddress(value)

    // 未入力の場合
    if (!value) {
      setShowAddressError(true)
      setAddressError(true)
      return
    }

    setShowAddressError(false)
    setAddressError(false)
  }
  const handleSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value)
    setSize(value)

    // 未入力の場合
    if (!value) {
      setShowSizeError(true)
      setSizeError(true)
      return
    }

    // 平米数は10.00 - 999.99までの数値で入力してください
    if (value < 10 || value > 999.99) {
      setShowSizeError(true)
      setSizeError(true)
      return
    }
    setShowSizeError(false)
    setSizeError(false)
  }

  const handleBuildYearMonthChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value
    setBuildYearMonth(value)

    // 未入力の場合
    if (!value) {
      setShowBuildYearMonthError(true)
      setBuildYearMonthError(true)
      return
    }

    // YY/mm日付フォーマットのバリデーション
    if (!validateYearMonthFormat(value)) {
      setShowBuildYearMonthError(true)
      setBuildYearMonthError(true)
      return
    }

    setShowBuildYearMonthError(false)
    setBuildYearMonthError(false)
  }

  const validateYearMonthFormat = (value: string) => {
    const parseDate = parse(value, 'yyyy/MM', new Date())
    return isValid(parseDate)
  }

  const onSetup = async () => {
    setIsLoading(true)
    // 未入力の場合は未処理とする
    if (address == null || size == null || buildYearMonth == null) {
      return
    }

    try {
      const results = await Promise.all([
        ActiveInventoryApi.setting(
          props.contractId,
          address,
          size,
          buildYearMonth
        ),
        SuccessfullyConcludedDealApi.setting(
          props.contractId,
          address,
          size,
          buildYearMonth
        ),
      ])

      if (results.filter((result) => result.data).length === 0) {
        props.onSearchNoData('notfound')
        return
      }
    } catch (e) {
      setIsLoading(false)
      props.onSearchNoData('error')
      return
    }

    setIsLoading(false)
    props.close()
    props.onSuccess()
  }

  return (
    <Body>
      <SubSectionTitle style={{ marginLeft: '10px', marginBottom: '20px' }}>
        売却する物件情報を入力してください。
      </SubSectionTitle>
      <InputArea>
        <Label htmlFor="address" labelName="住所" required={true} />
        <Text size={'sm'} style={{ marginLeft: '10px' }}>
          *市区もしくは町名まで入力してください。
          <br />
          都内であれば町名まで指定、それ以外では市区町村までの指定が目安です。
        </Text>
        <Input
          type="text"
          name="address"
          value={address}
          placeholder="東京都港区虎ノ門"
          aria-label="住所"
          onChange={handleAddressChange}
        />
        <ErrorText>{showAddressError && '住所を入力してください'}</ErrorText>
      </InputArea>
      <InputArea>
        <Label htmlFor="size" labelName="平米数(m2)" required={true} />
        <Text size={'sm'} style={{ marginLeft: '10px' }}>
          *入力値の近似値順に表示されます。
        </Text>
        <NumberInput
          type="number"
          name="size"
          value={size}
          placeholder="999.99"
          aria-label="平米数(m2)"
          onChange={handleSizeChange}
          onFocus={wheelDisabled}
          step="0.01"
        />

        <ErrorText>
          {showSizeError &&
            '平米数は10.00 - 999.99までの数値で入力してください'}
        </ErrorText>
      </InputArea>
      <InputArea>
        <Label htmlFor="buildYearMonth" labelName="築年月" required={true} />
        <Text size={'sm'} style={{ marginLeft: '10px' }}>
          *前後5年の物件情報が表示されます。
        </Text>
        <Input
          type="text"
          name="buildYearMonth"
          value={buildYearMonth}
          placeholder="2020/01"
          aria-label="築年月"
          onChange={handleBuildYearMonthChange}
        />
        <ErrorText>
          {showBuildYearMonthError && '正しい年月を指定してください'}
        </ErrorText>
      </InputArea>
      <SetupAndReturnButtons
        onReturn={props.onReturn}
        onClear={props.onClear}
        configurable={!hasError}
        onSetup={onSetup}
        isLoading={isLoading}
      />
    </Body>
  )
}

const ClearConfirm = (props: {
  onReturn: () => Promise<void>
  onExecute: () => Promise<void>
  isLoading: boolean
}) => {
  return (
    <Block>
      <Alert>
        設定した売出物件をクリアします。
        <br />
        再度設定することで売出物件を表示することができます。
        <br />
        <br />
        よろしいですか？
      </Alert>
      <PhraseWrapper>
        <Button onClick={props.onReturn} color={'fourth'}>
          <span>いいえ</span>
        </Button>
        <Button onClick={props.onExecute}>
          {props.isLoading && (
            <ReactLoading
              type="spin"
              color={theme.WHITE}
              height={20}
              width={20}
            />
          )}
          {!props.isLoading && <span>はい</span>}
        </Button>
      </PhraseWrapper>
    </Block>
  )
}

const NoData = (props: {
  onReturn: () => Promise<void>
  messageType?: string
}) => {
  const message =
    props.messageType === 'error' ? (
      <>
        <span>該当住所での周辺物件がありません。</span>
        <br />
        <span>
          市区町村や丁目等の番地情報を含めて再度お試しいただくか、実際に物件がない場合があります。
        </span>
      </>
    ) : (
      <span>該当住所での周辺物件がありません。</span>
    )

  return (
    <Block>
      <Error>{message}</Error>
      <PhraseWrapper>
        <Button onClick={props.onReturn} color={'fourth'}>
          <span>戻る</span>
        </Button>
      </PhraseWrapper>
    </Block>
  )
}

const SetupAndReturnButtons = (props: {
  configurable: boolean
  isLoading?: boolean
  onReturn: () => void
  onClear: () => void
  onSetup: () => Promise<void>
}) => (
  <PhraseWrapper>
    <Button onClick={props.onReturn} color={'fourth'}>
      <span>戻る</span>
    </Button>
    <Button onClick={props.onClear}>
      <span>クリア</span>
    </Button>
    <Button disabled={!props.configurable} onClick={props.onSetup}>
      {props.isLoading && (
        <ReactLoading type="spin" color={theme.WHITE} height={20} width={20} />
      )}
      {!props.isLoading && <span>設定</span>}
    </Button>
  </PhraseWrapper>
)

const SettingDialog = ({
  contractId,
  config,
  showDialog,
  onCancel,
  onSuccess,
}: {
  contractId: string
  config: ActiveInventoryConfig | undefined
  showDialog: boolean
  onCancel: () => void
  onSuccess: () => void
}) => {
  const [isSearchNoDate, setSearchNoDate] = useState(false)
  const [viewConfigInputDialog, setViewConfigInputDialog] = useState(true)
  const [viewClearConfirmDialog, setViewClearConfirmDialog] = useState(false)
  const [messageType, setMessageType] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const resetDialog = () => {
    setViewConfigInputDialog(true)
    setViewClearConfirmDialog(false)
    setSearchNoDate(false)
    setIsLoading(false)
  }
  const close = async () => {
    onCancel()
    resetDialog()
  }

  const returnToInput = async () => {
    resetDialog()
  }

  const toClearConfirm = async () => {
    setViewConfigInputDialog(false)
    setViewClearConfirmDialog(true)
  }

  const clearSetting = async () => {
    setIsLoading(true)
    await ActiveInventoryApi.clear(contractId)
    await SuccessfullyConcludedDealApi.clear(contractId)
    await close()
    onSuccess()
  }

  const viewNoData = (type: string) => {
    setMessageType(type)
    setSearchNoDate(true)
    setViewConfigInputDialog(false)
  }

  return (
    <>
      <Dialog
        show={showDialog}
        onClose={() => close()}
        styleOptions={{ position: 'top' }}
      >
        {viewConfigInputDialog && (
          <>
            <ConfigInput
              contractId={contractId}
              config={config}
              close={close}
              onReturn={close}
              onClear={toClearConfirm}
              onSuccess={onSuccess}
              onSearchNoData={viewNoData}
            />
          </>
        )}
        {viewClearConfirmDialog && (
          <>
            <ClearConfirm
              onReturn={returnToInput}
              onExecute={clearSetting}
              isLoading={isLoading}
            />
          </>
        )}

        {!viewConfigInputDialog && isSearchNoDate && (
          // 0件エラー
          <>
            <NoData onReturn={returnToInput} messageType={messageType} />
          </>
        )}
      </Dialog>
    </>
  )
}
export default PropertyExampleSettingDialog
